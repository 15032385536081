import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import common from '../../../shared/styles/common.module.scss';
import { useAuth } from '../../../shared/auth/Auth.context';
import { useGetCartQuery } from '../../../shared/graphql/client/useGetCartQuery';
import { useMutationAddProductById } from '../../../New/hooks/useMutationAddProductById';
import { ROUTES } from '../../../shared/constants';
import { graphqlClientFront, LinkType } from '../../../shared/graphql/clients';
import PercentageLoader from '../../../New/shared-components/ui/Loaders/PercentageLoader';

export const ProductByIdPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const { loadClientSession, session_id } = useAuth();

    const { cart } = useGetCartQuery(!session_id);

    const [addProductById] = useMutationAddProductById({
        onServerErrorCallback: loadClientSession,
        onCompleted: () => {
            if (cart?.id) {
                history.push(ROUTES.client.cartId.replace(':cartId', cart?.id));
            }
        },
    });

    useEffect(() => {
        if (id && cart?.id) {
            void addProductById({
                variables: { productID: id },
                context: { linkType: LinkType.Client },
                client: graphqlClientFront,
            });
        }
    }, [id, cart?.id]);

    return (
        <div className={common.LoadingCover}>
            <PercentageLoader />
        </div>
    );
};
