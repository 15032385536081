import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { api } from '../../api';
import s from './styles.module.scss';
import common from '../../shared/styles/common.module.scss';
import {
    Table,
    Input,
    Button,
    Space,
    Form,
    InputRef,
    Switch,
    notification,
    Modal,
    Checkbox,
    DatePicker,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { logout } from '../../shared/utils/token';
import ProductData from './ProductData';
import { Link } from 'react-router-dom';
import PercentageLoader from '../../New/shared-components/ui/Loaders/PercentageLoader';
import dayjs from 'dayjs';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface Item {
    category: string;
    number: string;
    color: string;
    max_discount: string;
    sell_up_before: string;
    outdated: string;
    img_link: string;
    collection: string;
    exception: boolean;
    price: string;
    aggregation_sign: string;
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;
    const datepickerRef = useRef<any>(null);

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        // form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            setEditing(false);
            handleSave({
                ...record,
                ...values,
                sell_up_before: values.sell_up_before?.format(),
            });
            setEditing(false);
        } catch (errInfo) {
            console.error('Save failed:', errInfo);
        }
    };

    const editableSell = useMemo(() => {
        switch (dataIndex) {
            case 'sell_up_before':
                return (
                    <DatePicker
                        ref={datepickerRef}
                        style={{ width: 150 }}
                        onChange={(_, dateString) => {
                            if (typeof dateString !== 'string') {
                                notification.error({
                                    message: `Некорректное значение даты: ${JSON.stringify(
                                        dateString
                                    )}`,
                                });

                                Sentry.captureException(
                                    new Error(
                                        `Некорректное значение даты: ${JSON.stringify(
                                            dateString
                                        )}`
                                    ),
                                    {
                                        data: {
                                            dateString,
                                        },
                                    }
                                );

                                return;
                            }

                            const date = dayjs(dateString);

                            if (!date.isValid()) {
                                notification.error({
                                    message: `Некорректное значение даты: ${dateString}`,
                                });

                                Sentry.captureException(
                                    new Error(
                                        `Некорректное значение даты: ${JSON.stringify(
                                            dateString
                                        )}`
                                    ),
                                    {
                                        data: {
                                            dateString,
                                        },
                                    }
                                );

                                return;
                            }

                            form.setFieldValue(
                                dataIndex,
                                // @ts-ignore
                                dayjs(dateString)
                            );
                            void save();
                        }}
                        defaultValue={dayjs(record[dataIndex])}
                    />
                );
            case 'exception':
                return <Switch checked={record[dataIndex]} onChange={save} />;
            case 'max_discount':
                return (
                    <Input
                        defaultValue={record?.[dataIndex] || ''}
                        type="number"
                        min={0}
                        max={100}
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        allowClear
                    />
                );
            default:
                return (
                    <Input
                        defaultValue={record?.[dataIndex] || ''}
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        allowClear
                    />
                );
        }
    }, [record, editing, dataIndex]);

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                normalize={
                    dataIndex === 'sell_up_before'
                        ? (value) => (value ? dayjs(value) : '')
                        : undefined
                }
            >
                {editableSell}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24, width: 100, minHeight: 50 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    // @ts-ignore
    return <td {...restProps}>{childNode}</td>;
};

const ShopAdmin = () => {
    const [items, setItems] = useState<any[]>([]);
    const [currentData, setCurrentData] = useState<any[]>([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

    const [bulkModalVisible, setBulkModalVisible] = useState(false);

    const [isLoading, toggleLoading] = useState<boolean>(false);
    const [shouldRemovePhoto, toggleShouldRemovePhoto] =
        useState<boolean>(false);

    const [showProduct, setShowProduct] = useState<
        | false
        | {
              aggregated_product: ProductType;
              products: ProductType[];
          }
    >(false);

    const [fieldsForBulkEdit, setFieldsForBulkEdit] = useState<{
        max_discount: boolean;
        sell_up_before: boolean;
        img_link: boolean;
        exception: boolean;
    }>({
        max_discount: false,
        sell_up_before: false,
        img_link: false,
        exception: false,
    });

    const searchInput = useRef<any>();

    const [form] = Form.useForm();

    const getItems = async () => {
        try {
            toggleLoading(true);
            const { data } = await api.shop_admin();
            setItems(data.list);
            setCurrentData(data.list);
        } catch (e) {
            notification.error({
                // @ts-ignore
                message: e.data?.error?.message || 'Неверные данные',
            });
        } finally {
            toggleLoading(false);
        }
    };

    useEffect(() => {
        getItems();
    }, []);

    const handleSave = async (row) => {
        try {
            toggleLoading(true);
            const { data } = await api.shop_admin_patch(row.aggregation_sign, {
                exception: row.exception,
                max_discount: Number(row.max_discount),
                img_link: row.img_link,
                sell_up_before: row.sell_up_before,
            });
            if (data.aggregation_sign) {
                setItems(
                    items.map((item) => {
                        if (item.aggregation_sign === data.aggregation_sign) {
                            return data;
                        }
                        return item;
                    })
                );
            }
            notification.success({
                message: 'Изменения сохранены',
            });
            //setItems(data.list);
        } catch (e) {
            notification.error({
                message:
                    // @ts-ignore
                    e.data?.error?.message || 'Не удалось сохранить изменения',
            });
        } finally {
            toggleLoading(false);
        }
    };

    const bulkSave = async (values) => {
        try {
            toggleLoading(true);
            const valuesToSave = {};
            Object.keys(fieldsForBulkEdit).forEach((field) => {
                if (fieldsForBulkEdit[field]) {
                    switch (field) {
                        case 'max_discount':
                            valuesToSave[field] = Number(values[field]);
                            break;
                        case 'sell_up_before':
                            valuesToSave[field] = values[field].format();
                            break;
                        case 'img_link':
                            valuesToSave[field] = shouldRemovePhoto
                                ? ''
                                : values[field];
                            break;
                        default:
                            valuesToSave[field] = values[field];
                    }
                }
            });
            const changeRequests = selectedRowKeys.map(
                async (aggregationSign) =>
                    await api.shop_admin_patch(
                        aggregationSign as string,
                        valuesToSave
                    )
            );

            await Promise.all(changeRequests);

            await getItems();

            setBulkModalVisible(false);

            toggleShouldRemovePhoto(false);
            form.resetFields();
        } catch (e) {
            notification.error({
                message:
                    // @ts-ignore
                    e.data?.error?.message || 'Не удалось сохранить изменения',
            });
        } finally {
            toggleLoading(false);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                //@ts-ignore
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const categoriesFilter = () => {
        // @ts-ignore
        const nonRepeatItems = [...new Set(items.map((item) => item.category))];
        return nonRepeatItems.map((category) => ({
            text: category,
            value: category,
        }));
    };

    const collectionFilter = () => {
        const nonRepeatItems = [
            // @ts-ignore
            ...new Set(items.map((item) => item.collection)),
        ];
        return nonRepeatItems.map((collection) => ({
            text: collection,
            value: collection,
        }));
    };

    const handleGetProduct = async (aggregationSign: string) => {
        try {
            const { data } = await api.shop_admin_product(aggregationSign);
            setShowProduct(data);
        } catch (e) {
            console.error(e);
        }
    };

    const columns = [
        {
            title: 'Категория',
            dataIndex: 'category',
            filters: categoriesFilter(),
            onFilter: (value, record) => record.category === value,
        },
        {
            title: 'Артикул',
            dataIndex: 'number',
            ...getColumnSearchProps('number'),
        },
        {
            title: 'Цвет',
            dataIndex: 'color',
        },
        {
            title: 'Максимальная скидка (%)',
            dataIndex: 'max_discount',
            onCell: (record) => {
                return {
                    record,
                    editable: true,
                    dataIndex: 'max_discount',
                    title: 'Максимальная скидка (%)',
                    handleSave,
                };
            },
            sorter: (a, b) => a.max_discount - b.max_discount,
        },
        {
            title: 'Продать до',
            dataIndex: 'sell_up_before',
            onCell: (record) => ({
                record,
                editable: true,
                dataIndex: 'sell_up_before',
                title: 'Продать до',
                handleSave,
            }),
            //@ts-ignore
            sorter: (a, b) =>
                Number(dayjs(a.sell_up_before).format('YYYYMMDD')) -
                Number(dayjs(b.sell_up_before).format('YYYYMMDD')),
            render: (text) =>
                text ? (
                    <div style={{ width: 150 }}>
                        {dayjs(text).format('DD.MM.YYYY')}
                    </div>
                ) : null,
        },
        {
            title: 'Фото',
            dataIndex: 'img_link',
            render: (text: string) => (
                <img className={s.ItemImage} src={text} alt="" />
            ),
            sorter: (a, b) =>
                !!a.img_link === !!b.img_link ? 0 : a.img_link ? -1 : 1,
            onCell: (record) => ({
                record,
                editable: true,
                dataIndex: 'img_link',
                title: 'Фото',
                handleSave,
            }),
        },
        {
            title: 'Коллекция',
            dataIndex: 'collection',
            filterSearch: true,
            filters: collectionFilter(),
            onFilter: (value, record) => record.collection === value,
        },
        {
            title: 'Исключение',
            dataIndex: 'exception',
            filters: [
                { text: 'Да', value: true },
                { text: 'Нет', value: false },
            ],
            onFilter: (value, record) => record.exception === value,
            onCell: (record) => ({
                record,
                editable: true,
                dataIndex: 'exception',
                title: 'Исключение',
                handleSave,
            }),
            render: (text) => (text ? 'Да' : 'Нет'),
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            ...getColumnSearchProps('price'),
            sorter: (a, b) => a.price - b.price,
        },
        {
            title: '',
            dataIndex: 'aggregation_sign',
            render: (value) => (
                <Button size={'small'} onClick={() => handleGetProduct(value)}>
                    Подробнее
                </Button>
            ),
        },
    ];

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const checkField = (field: string, check) => {
        if (!check) {
            setFieldsForBulkEdit((state) => ({
                ...state,
                [field]: false,
            }));
        } else if (!fieldsForBulkEdit[field]) {
            setFieldsForBulkEdit((state) => ({
                ...state,
                [field]: true,
            }));
        }
    };

    const handleSelect = (record, selected) => {
        if (selected) {
            setSelectedRowKeys((keys) => [...keys, record.aggregation_sign]);
        } else {
            setSelectedRowKeys((keys) => {
                const index = keys.indexOf(record.aggregation_sign);
                return [...keys.slice(0, index), ...keys.slice(index + 1)];
            });
        }
    };

    const toggleSelectAll = () => {
        setSelectedRowKeys((keys) =>
            keys.length === items.length
                ? []
                : currentData.map((r) => r.aggregation_sign)
        );
    };

    const headerCheckbox = (
        <Checkbox
            checked={!!selectedRowKeys.length}
            indeterminate={
                selectedRowKeys.length > 0 &&
                selectedRowKeys.length < items.length
            }
            onChange={toggleSelectAll}
        />
    );

    return (
        <div className={common.AdminPage}>
            <ProductData
                visible={!!showProduct}
                onCancel={() => setShowProduct(false)}
                data={showProduct || undefined}
            />
            <Modal
                title={
                    <div>
                        Множественное редактирование{' '}
                        {isLoading && <PercentageLoader />}
                    </div>
                }
                open={bulkModalVisible}
                onOk={form.submit}
                okText="Отредактировать"
                cancelText="Отмена"
                onCancel={() => setBulkModalVisible(false)}
                okButtonProps={{
                    disabled: isLoading,
                }}
            >
                <div>{`Будет отредактировано ${selectedRowKeys.length} товаров`}</div>
                <Form form={form} onFinish={bulkSave}>
                    <div className={s.FormRow}>
                        <Checkbox
                            className={s.FormCheckbox}
                            checked={fieldsForBulkEdit.max_discount}
                            onClick={() =>
                                checkField(
                                    'max_discount',
                                    !fieldsForBulkEdit.max_discount
                                )
                            }
                        />
                        <Form.Item
                            label="Максимальная скидка"
                            name="max_discount"
                        >
                            <Input
                                type={'number'}
                                min={0}
                                max={100}
                                onChange={() =>
                                    checkField('max_discount', true)
                                }
                            />
                        </Form.Item>
                    </div>
                    <div className={s.FormRow}>
                        <Checkbox
                            className={s.FormCheckbox}
                            checked={fieldsForBulkEdit.sell_up_before}
                            onClick={() =>
                                checkField(
                                    'sell_up_before',
                                    !fieldsForBulkEdit.sell_up_before
                                )
                            }
                        />
                        <Form.Item label="Продать до" name="sell_up_before">
                            <DatePicker
                                onChange={() =>
                                    checkField('sell_up_before', true)
                                }
                            />
                        </Form.Item>
                    </div>
                    <div className={s.FormRow}>
                        <Checkbox
                            className={s.FormCheckbox}
                            checked={fieldsForBulkEdit.img_link}
                            onClick={() =>
                                checkField(
                                    'img_link',
                                    !fieldsForBulkEdit.img_link
                                )
                            }
                        />
                        <Form.Item label="Фото" name="img_link">
                            <Input
                                onChange={() => checkField('img_link', true)}
                            />
                        </Form.Item>
                        <div className={s.RemovePhoto}>
                            Удалить фото{' '}
                            <Switch
                                checked={shouldRemovePhoto}
                                onChange={(checked) => {
                                    checkField('img_link', true);
                                    toggleShouldRemovePhoto(checked);
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.FormRow}>
                        <Checkbox
                            className={s.FormCheckbox}
                            checked={fieldsForBulkEdit.exception}
                            onClick={() =>
                                checkField(
                                    'exception',
                                    !fieldsForBulkEdit.exception
                                )
                            }
                        />
                        <Form.Item label="Исключение" name="exception">
                            <Switch
                                onChange={() => checkField('exception', true)}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
            <div className={s.TableControls}>
                <Button
                    type="primary"
                    disabled={!selectedRowKeys.length}
                    onClick={() => setBulkModalVisible(true)}
                >
                    Отредактировать выбранные
                </Button>
                <div>
                    {`Выбрано элементов: ${selectedRowKeys.length}`}
                    <Button
                        disabled={!selectedRowKeys.length}
                        onClick={() => setSelectedRowKeys([])}
                        className={s.ResetButton}
                    >
                        Сбросить
                    </Button>
                </div>
                <div className={s.PageControls}>
                    <Link
                        to={`/axaxax36ax/statistics/sales_summary`}
                        className={s.Link}
                    >
                        {'Сводный отчёт'}
                    </Link>
                    <Link
                        to={`/axaxax36ax/statistics/product_ratings`}
                        className={s.Link}
                    >
                        {'Рейтинги товаров'}
                    </Link>
                    <Button className={s.Button} onClick={logout}>
                        Выход
                    </Button>
                </div>
            </div>
            <Table
                pagination={{
                    defaultPageSize: 100,
                }}
                //pagination={pagination === false ? false : undefined}
                onChange={(pagination, filters, sorter, extra) => {
                    setCurrentData(extra.currentDataSource);
                }}
                rowKey={'aggregation_sign'}
                loading={isLoading}
                columns={columns}
                dataSource={items}
                components={components}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    selectedRowKeys,
                    columnTitle: headerCheckbox,
                    onSelect: handleSelect,
                }}
            />
        </div>
    );
};

export default ShopAdmin;
