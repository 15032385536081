import { RetryLink } from '@apollo/client/link/retry';
import {
    getAdminSession,
    getSellerSession,
    removeAsconaSession,
    removeClientSession,
} from '../utils/storage';
import {
    ApolloClient,
    ApolloLink,
    concat,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { clientSession, redirectToMainPage } from '../auth';
import * as Sentry from '@sentry/react';

export enum LinkType {
    Client = 'client',
    Seller = 'seller',
    SuperAdmin = 'superadmin',
}

const superAdminLink = new RetryLink({ attempts: { max: 0 } }).split(
    (operation) => {
        if (
            operation.getContext().linkType === LinkType.SuperAdmin &&
            !!getAdminSession()
        ) {
            operation.setContext({
                ...operation.getContext(),
                headers: { token: getAdminSession() },
            });
            return true;
        }
        return false;
    },
    new HttpLink({
        uri: `${process.env.REACT_APP_API_PATH}superadmin/graphql`,
    }),
    undefined
);

const sellerLink = new RetryLink({ attempts: { max: 0 } }).split(
    (operation) => {
        if (
            operation.getContext().linkType === LinkType.Seller &&
            !!getSellerSession()
        ) {
            operation.setContext({
                ...operation.getContext(),
                headers: { token: getSellerSession() },
            });
            return true;
        }
        return false;
    },
    new HttpLink({ uri: `${process.env.REACT_APP_API_PATH}salesman/graphql` }),
    undefined
);

const clientLinkAuthMiddleware = new ApolloLink((operation, forward) => {
    const session = clientSession.get();

    if (
        operation.getContext().linkType === LinkType.Client &&
        Boolean(session?.session_id)
    ) {
        if (
            new Date(session?.expires_at || '') < new Date() &&
            !getSellerSession()
        ) {
            removeClientSession();
            removeAsconaSession();

            Sentry.withScope(function (scope) {
                scope.setExtra('batnaSessionData', session);

                Sentry.captureException(new Error('Session expired'));
            });

            redirectToMainPage();
        } else {
            operation.setContext({
                ...operation.getContext(),
                headers: { session: session?.session_id },
            });
        }
    }

    return forward(operation);
});

const clientLink = new RetryLink({ attempts: { max: 0 } }).split(
    () => true,
    new HttpLink({ uri: `${process.env.REACT_APP_API_PATH}front/graphql` }),
    undefined
);

export const graphqlClientSuperAdmin = new ApolloClient({
    link: superAdminLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

export const graphqlClientSeller = new ApolloClient({
    link: sellerLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

export const graphqlClientFront = new ApolloClient({
    link: concat(clientLinkAuthMiddleware, clientLink),
    cache: new InMemoryCache({
        typePolicies: {
            Cart: {
                fields: {
                    cartProducts: {
                        // Custom merge function
                        merge(_ = [], incoming) {
                            // Merge logic to combine existing and incoming cart products
                            return [...incoming];
                        },
                    },
                },
            },
        },
    }),
    connectToDevTools: true,
});
