import React, { useEffect } from 'react';
import PercentageLoader from '../shared-components/ui/Loaders/PercentageLoader';
import styles from './styles.module.scss';
import { Trade } from '../features/trade';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { ProductList } from '../features/product-list/components';
import { t } from 'i18next';
import { BuyContainer } from '../features/buy-container';
import { LangDropdown } from '../shared-components/ui/Dropdowns/LangDropdown';
import { UpdatedLvlInfo } from '../features/updated-lvl-info';
import CloseButton from '../shared-components/ui/Buttons/CloseButton';
import { setCatalogReload } from '../../shared/utils/storage';
import common from '../../shared/styles/common.module.scss';
import { ROUTES } from '../../shared/constants';
import { useGetCartQuery } from '../../shared/graphql/client/useGetCartQuery';
import { useAuth } from '../../shared/auth/Auth.context';
import { OfferState } from '../services/graphql';

const Cart = () => {
    const { cartId } = useParams<{ cartId: string }>();
    const history = useHistory();

    const { session_id } = useAuth();

    const { cart, cartLoading, cartRefetch, cartPreviousData } =
        useGetCartQuery(!session_id);

    useEffect(() => {
        void cartRefetch();
    }, [cartId]);

    useEffect(() => {
        if (cartPreviousData) {
            const lastCartElementsCount = cart?.cartProducts?.reduce(
                (acc, item) => {
                    return acc + Number(item?.count);
                },
                0
            );
            const lastCatalogCartElementsCount =
                cartPreviousData?.cart?.cartProducts?.reduce((acc, item) => {
                    return acc + Number(item.count);
                }, 0);
            if (
                cartPreviousData?.cart.level !== cart?.level ||
                cartPreviousData?.cart?.targetLevel !== cart?.targetLevel ||
                cartPreviousData?.cart?.tradeComplete !== cart?.tradeComplete ||
                lastCartElementsCount !== lastCatalogCartElementsCount
            ) {
                setCatalogReload(true);
            }
        }
    }, [cart]);

    if (cart && cart?.cartProducts?.length === 0 && !cart?.tradeComplete) {
        return <Redirect to={ROUTES.client.catalog} />;
    }

    if (cartLoading) {
        return (
            <div className={common.LoadingCover} style={{ display: 'flex' }}>
                <PercentageLoader />
            </div>
        );
    }

    return (
        <div className={styles.cart}>
            <div className={styles.cart__header}>
                <span>{t('20_Cart')}</span>
                <div className={styles['cart__close-and-lang-dropdown']}>
                    <LangDropdown />
                    {cart?.tradeComplete && (
                        <CloseButton
                            onClose={() => {
                                history.push(ROUTES.client.catalog);
                            }}
                        />
                    )}
                </div>
            </div>
            {cart ? (
                <>
                    <ProductList
                        showHintAfterTrade={cart?.tradeComplete}
                        products={cart.cartProducts || []}
                        offerState={cart.offerState as OfferState}
                        targetLevel={cart.targetLevel}
                    />
                    <BuyContainer
                        isTradeCompleted={cart.tradeComplete}
                        tradeControls={<Trade cart={cart} />}
                    />
                    <UpdatedLvlInfo level={cart.level} />
                </>
            ) : null}
        </div>
    );
};

export default Cart;
