import { useMutation } from '@apollo/client';
import { GET_CART } from '../../../services/graphql';
import * as Sentry from '@sentry/react';
import { useAuth } from '../../../../shared/auth/Auth.context';
import {
    MutationTradeArgs,
    TradeMutation,
} from '../../../../shared/graphql/client/generated/graphql.types';
import { TRADE } from '../../../../shared/graphql/client/graphql';

type Params = {
    onCompleted?: () => void;
};

export const useMutationSendTradeOffer = ({ onCompleted }: Params = {}) => {
    const { session_id } = useAuth();

    return useMutation<TradeMutation, MutationTradeArgs>(TRADE, {
        fetchPolicy: 'network-only',
        update: (cache, { data }) => {
            cache.writeQuery({
                query: GET_CART,
                data: { cart: data?.trade || {} },
            });
        },
        onError: (error) => {
            Sentry.withScope(function (scope) {
                scope.setExtra('error_message', 'Error sending trade offer');
                scope.setExtra('session_id', session_id);

                Sentry.captureException(error);
            });

            console.error('Error sending trade offer:', error);
        },
        onCompleted,
    });
};
