import React, { FC } from 'react';
import { Popover, Table, Tag } from 'antd';
import {
    ActionAlias,
    ActionColor,
    getFinalDisplayPricesForCartTypeEvents,
    getFinalStepForCartTypeEvents,
    getPrice,
    groupBy,
} from './CartsPage';
import { maximumProductsPricesLength, parseAttempt } from './utils';
import {
    ProductMetrics,
    SingleMetrics,
} from '../../../shared/graphql/superadmin/generated/graphql.types';
import { CartTypeEnum } from '../../../shared/types';

interface Props {
    eventsData: SingleMetrics[];
    cartType?: CartTypeEnum;
}

const actionDesc = {
    actionName: 'Название действия',
    displayPrice: 'Конечные цены',
    gain: 'gain',
    cartPrice: 'Сумма товаров в корзине',
    discount: 'Средняя скидка товаров корзины',
    deltaTime: 'Время в секундах с предыдущего действия в корзине',
};

export const CartEventsTable: FC<Props> = ({ eventsData, cartType }) => {
    const localEventsData = eventsData || [];
    const groupedDisplayPricesByAs = groupBy(
        localEventsData.flatMap((event, index) =>
            event.productsMetrics?.map((tp2) => ({ ...tp2, index, event }))
        ),
        (item) =>
            `${item?.aggregationSign}-${item?.cartProductID}-${item?.count}`
    );
    const displayPricesTable = localEventsData.map((_, index) =>
        Object.keys(groupedDisplayPricesByAs)
            .sort((a, b) => (a.length > b.length ? -1 : 1))
            .map((as) =>
                groupedDisplayPricesByAs[as].find(
                    (item) => item?.index === index
                )
            )
    );

    const columns = (obj, fixed?: string[], width?: number) =>
        Object.keys(obj).map((key) => {
            let params: any = {
                title: () => (
                    <Popover placement={'bottomLeft'} content={obj[key]}>
                        <span style={{ width: '100%', display: 'block' }}>
                            {key}
                        </span>
                    </Popover>
                ),
                dataIndex: key,
                key,
                width: width || 100,
            };

            !['actionName', 'cartID', 'orderID', 'juke', 'date'].includes(
                key
            ) && (params = { ...params, sorter: (a, b) => a[key] - b[key] });

            fixed?.includes(key) && (params = { ...params, fixed: true });

            key === 'displayPrice' &&
                (params = {
                    ...params,
                    width: maximumProductsPricesLength(localEventsData),
                    className: 'cell-green',
                });

            key === 'date' &&
                (params = {
                    ...params,
                    width: 180,
                    fixed: true,
                });
            key === 'gain' &&
                (params = {
                    ...params,
                    render: (_, data) => {
                        const checkGap = (data.checkGap || 0) / 100;
                        const checkMd = (data.checkMD || 0) / 100;
                        return (
                            <div>
                                <div>{checkGap}</div>
                                <div>{`(${checkMd})`}</div>
                            </div>
                        );
                    },
                });
            key === 'displayPriceLTPriceCount' &&
                (params = { ...params, width: 130 });
            key === 'cartPrice' &&
                (params = {
                    ...params,
                    render: (data) => (data ? getPrice(data) : null),
                });
            key === 'discount' &&
                (params = {
                    ...params,
                    render: (text) => Math.round(text) || '',
                });
            key === 'actionName' &&
                (params = {
                    ...params,
                    fixed: true,
                    render: (text, data) => {
                        if (text === 'Trade') {
                            return parseAttempt(data.actionParams);
                        }

                        return text ? (
                            <Tag color={ActionColor[text]}>
                                {ActionAlias[text]}
                            </Tag>
                        ) : null;
                    },
                    width: 100,
                });

            return params;
        });

    const dataSource: { [key: string]: any }[] = localEventsData.map(
        (step, index) => {
            return {
                ...step,
                step: index + 1,
                key: index + 1,
                date: step.date?.split('.')?.[0],
                checkGap: step.checkGap
                    ? Math.round(step.checkGap * 1000) / 1000 / 100
                    : 0,
                discount: step.discount
                    ? Math.round(step.discount * 1000) / 1000 / 100
                    : 0,
                gain: step.gain ? Math.round(step.gain * 1000) / 1000 / 100 : 0,
                tradeDiscount: step.tradeDiscount
                    ? Math.round(step.tradeDiscount * 1000) / 1000 / 100
                    : 0,
                displayPrice: getFinalDisplayPricesForCartTypeEvents(
                    index,
                    displayPricesTable,
                    Object.keys(groupedDisplayPricesByAs).length,
                    cartType
                ),
            };
        }
    );

    const getProductsEventsData = () => {
        return Object.keys(groupedDisplayPricesByAs)
            .sort((a, b) => (a.length > b.length ? -1 : 1))
            .map(
                (as) =>
                    groupedDisplayPricesByAs[as][
                        groupedDisplayPricesByAs[as].length - 1
                    ]
            );
    };

    dataSource.push({
        cartPrice: null,
        step: null,
        key: null,
        date: null,
        checkGap: null,
        discount: null,
        gain: null,
        tradeDiscount: null,
        displayPrice: getFinalStepForCartTypeEvents(
            getProductsEventsData() as ProductMetrics[],
            Object.keys(groupedDisplayPricesByAs).length,
            cartType
        ),
    });

    return (
        <Table
            className={'simple-table'}
            scroll={{ x: 1200 }}
            columns={columns(actionDesc, ['step', 'actionName'])}
            dataSource={dataSource}
            pagination={false}
        />
    );
};
